import * as React from 'react'
import { Container, Row, Col } from 'react-bootstrap';

import LogoImage from '../images/bamboo2_w4000.jpg';
import LogoWeiss from '../../static/Logo_white.svg'
import LastenradManufaktur from '../../static/Lastenrad_Manufaktur.svg'

const Header = () => {
    return (
        <header id="home" className="container-fluid text-white d-flex justify-content-center align-items-center"
            style={{
                backgroundImage: `url(${LogoImage})`,
                height: "50vw",
                maxHeight: "55vh",
                minHeight: "28em"
            }}>
            <div className="mt-5 mb-0 text-center" >
                <div><img src={LogoWeiss} alt="LaMa Logo" className="img-fluid" style={{ maxWidth: "45%", maxHeight: "25vh" }} /></div>
                <div><img src={LastenradManufaktur} alt="Lastenrad Manufaktur" className="img-fluid" style={{ maxWidth: "80%", maxHeight: "25vh" }} /></div>
            </div>
        </header >
    )
}

export default Header
