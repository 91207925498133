

function scroll_to(element_id: string, nav_height: number) {
  console.log(element_id)
  var scroll_to = 0;
  if (element_id != 'home') {
    const offsetTop = document.getElementById(element_id)?.offsetTop ?? 0
    const offset = 20;
    console.debug('top', offsetTop)
    scroll_to = offsetTop - nav_height - offset;
  }
  console.log('now scroll to', scroll_to)
  window.scrollTo({ top: scroll_to, left: 0, behavior: "smooth" });
}


export function doScroll(e: any) {
  e.preventDefault();
  const clicked_link = e.currentTarget.hash.replace("#", "");
  const navbarHeight = document.getElementById('navbar')?.offsetHeight ?? 0;
  scroll_to(clicked_link, navbarHeight);
}
