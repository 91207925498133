import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';


const ApiResult = () => {
    function errorCallback(reason: any) {
        console.error(`Error: ${reason}`)
    }

    //https://www.gatsbyjs.com/docs/conceptual/data-fetching/#fetching-data-at-client-side-runtime
    const [getResponse, setGetResponse] = useState("default")
    //const [postResponse, setPostResponse] = useState("default")
    useEffect(() => {
        
        fetch(`https://api.bambuslastenfahrrad.de/get`)
            .then(response => response.json()) // parse JSON from request
            .then(resultData => {
                setGetResponse(resultData.Text as string)
            })
            .catch(errorCallback)
        

        /*
        const doPost = async () => {
            // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
            const response = await fetch(`http://ubuntu:8080/report`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: '{"Message": "hallo"}'
            })
            const data = await response.json()
            return data

        }
        doPost()
            .then((data) => {
                console.log("Response:", data)
                setPostResponse(data.Text)
            })
            .catch(errorCallback)
        */

    }, [])

    return (
            <div></div>
    )
}

export default ApiResult