import React, { useState, useCallback } from 'react'

import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { Modal, Button, Alert } from 'react-bootstrap';

type FormData = {
    name: string,
    email: string,
    message: string
}

const initialFormData: FormData = {
    name: "",
    email: "",
    message: "",
};

async function postFormData(formData: FormData) {
    // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
    const response = await fetch(`https://api.bambuslastenfahrrad.de/contact`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(formData)
    })
    const data = await response.json()
    console.log("Response:", data)

}

const ContactForm = ({ }) => {
    // Maybe just use this:
    // https://react-hook-form.com/get-started

    const [sentAlertIsShown, setSentAlert] = React.useState(false);
    const [sentSuccessAlertIsShown, setSentSuccessAlert] = React.useState(false);


    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal(e) {
        e.preventDefault();
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const [formData, setFormData] = useState(initialFormData);

    const updateFormDataHandler = useCallback(
        (field: string) => (event: React.SyntheticEvent) => {
            setFormData({ ...formData, [field]: event.target.value });
        },
        [formData]
    );

    function handleReset() {
        setFormData(initialFormData);
    }

    // type as suggested here: https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/forms_and_events/
    // Use https://react-hook-form.com/ if it gets more sophisticated
    function handleSubmit(event: React.SyntheticEvent) {
        event.preventDefault();
        console.log(formData);

        postFormData(formData)
            .then(() => {
                setFormData(initialFormData);
                setSentSuccessAlert(true)
                setTimeout(() => setSentSuccessAlert(false), 4000)
            })
            .catch((error) => {
                console.error("Posting form data:", error)
                setSentAlert(true)
                setTimeout(() => setSentAlert(false), 2000)
            })
    }

    return (
        <div>
            <Container>
                <a href="" className="link-secondary" onClick={openModal}>Sende uns eine Nachricht</a>
            </Container>

            <Modal show={modalIsOpen} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Sende uns eine Nachricht</Modal.Title>
                </Modal.Header>

                <Alert className='m-3' variant='danger' show={sentAlertIsShown}>
                    Senden des Formulars ist fehlgeschlagen.
                </Alert>
                <Alert className='m-3' variant='success' show={sentSuccessAlertIsShown}>
                    Senden des Formulars erfolgreich.
                </Alert>

                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Dein Name"
                                value={formData.name}
                                onChange={updateFormDataHandler("name")}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>E-Mail Addresse</Form.Label>
                            <Form.Control type="email" placeholder="Deine E-Mail Adresse"
                                value={formData.email}
                                onChange={updateFormDataHandler("email")}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicMessage">
                            <Form.Label>Nachricht</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Deine Nachricht an uns"
                                value={formData.message}
                                onChange={updateFormDataHandler("message")}
                            />
                        </Form.Group>
                        <Button className="mx-1" variant="secondary" type="submit">
                            Abschicken
                        </Button>
                        <Button className="mx-1" variant="secondary" type="reset" onClick={handleReset}>
                            Zurücksetzen
                        </Button>
                    </Form>
                </Modal.Body>

            </Modal>
        </div>
    );
}

export default ContactForm