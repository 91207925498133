import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { StaticImage } from "gatsby-plugin-image"

import { Link } from "react-scroll";

interface MyLinkProps {
  target: string,
  handleCollapse: () => void,
  children: any
}

const MyLink = (props: MyLinkProps) => {
  return (
    <Link className="nav-link"
      activeClass="active"
      to={props.target}
      spy={true}
      hashSpy={true}
      smooth={true}
      offset={-70}
      duration={500}
      href={"#" + props.target}
      //onSetActive={props.handleToggle}
      onClick={props.handleCollapse}
    >{props.children}</Link>
  )
}

const MyNavbar = () => {
  const [expanded, setExpanded] = React.useState(false);

  const collapse = () => {
    if (expanded) {
      setExpanded(false);
    }
  }

  const toggleExpanded = () => {
    setExpanded(!expanded);
  }

  return (
    <Navbar id='navbar' collapseOnSelect={true} bg="dark" expand="md" fixed="top" className="navbar-dark RobotoSlabFont" expanded={expanded}>
      <Container fluid>
        <Navbar.Brand>
          <MyLink handleCollapse={() => { }} target="home">
            <StaticImage src='../../static/Logo_white.svg' alt="Logo" height={50} />
          </MyLink>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleExpanded} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">

            <MyLink target="home" handleCollapse={collapse}>Home</MyLink>
            <MyLink target="bambuslastenfahrrad" handleCollapse={collapse}>Bambuslastenfahrrad</MyLink>
            <MyLink target="team" handleCollapse={collapse}>Wir sind LaMa</MyLink>
            <MyLink target="interesse" handleCollapse={collapse}>Interesse</MyLink>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );

}

export default MyNavbar