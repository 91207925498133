import * as React from "react"
import type { HeadFC } from "gatsby"

import MyNavbar from '../components/navbar'
import Header from '../components/header'
import Box from '../components/box'
import ApiResult from '../components/api'
import ContactForm from '../components/contactform'

import { StaticImage } from "gatsby-plugin-image"

import "../styles/index.css"
import 'bootstrap/dist/css/bootstrap.min.css';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { doScroll } from '../scroll'

const IndexPage = () => {
  return (
    <main style={{ backgroundColor: "#8ac7aa", paddingTop: "56px" }} className="m-0 pb-3">
      <MyNavbar />

      <Header />

      <Box boxTitle='Bambuslastenfahrrad' style='sand'>
        <div className="text-center sand-box">
          <h1>In Handarbeit fertigen wir hochwertige Lastenfahrräder aus Bambus -
            mit dem Anspruch so nachhaltig und fair wie möglich zu arbeiten.</h1>
        </div>

        <Row>
          <div className="col-md-4">
            <a onClick={doScroll} className="scroll-link text-decoration-none text-reset text-center" href="#die-idee">
              <div className="rounded shadow m-4 p-2" style={{ backgroundColor: 'white', width: '90%' }}>
                <StaticImage src="https://mianzi.gitlab.io/mianzi-website/img/orig/idee.png" alt="Die Idee" className="symbolic-image" />
                <p>Idee</p>
              </div>
            </a>
          </div>

          <div className="col-md-4">
            <a onClick={doScroll} className="scroll-link text-decoration-none text-reset text-center" href="#bambus">
              <div className="rounded shadow m-4 p-2" style={{ backgroundColor: 'white', width: '90%' }}>
                <StaticImage src="https://mianzi.gitlab.io/mianzi-website/img/orig/blatt.png" alt="Deshalb Bambus" className="symbolic-image" />
                <p>Bambus</p>
              </div>
            </a>
          </div>

          <div className="col-md-4">
            <a onClick={doScroll} className="scroll-link text-decoration-none text-reset text-center" href="#fahrrad">
              <div className="rounded shadow m-4 p-2" style={{ backgroundColor: 'white', width: '90%' }}>
                <StaticImage src="https://mianzi.gitlab.io/mianzi-website/img/orig/fahrrad.png" alt="Unser Fahrrad" className="symbolic-image" />
                <p>Fahrrad</p>
              </div>
            </a>
          </div>
        </Row>
      </Box>

      <Box boxTitle='Die Idee'>
        <StaticImage src="https://mianzi.gitlab.io/mianzi-website/img/orig/idee.png" alt="Die Idee" className="float-end symbolic-image" />
        <div className="text-center">
          <h1>Die Idee</h1>
        </div>
        <Row>
          <Col md={7}>
            <StaticImage src="https://mianzi.gitlab.io/mianzi-website/img/idee.jpg" alt="Studium der Pläne" className="p-3" style={{ borderRadius: "5px" }} />
          </Col>
          <Col md={5} className="text-secondary">
            <p>Einst reiste unser Freund Jonas nach Tansania und stieß auf eine einzigartige und umweltfreundliche Art der Fortbewegung: Bambus-Fahrräder. </p>
            <p>Er war von der Handwerkskunst und Nachhaltigkeit dieser Räder inspiriert und beschloss, diese Idee mit nach Ingolstadt zu bringen, wo er zu dieser Zeit studierte.</p>
            <p>Nach vielen Monaten der Recherche und Entwicklung haben wir zusammen einen ersten Prototypen kreiert, der nicht nur umweltfreundlich, sondern auch langlebig und stilvoll ist. </p>
            <p> Unser Ziel: Ein Fahrrad, das gut für die Umwelt ist und mit dem du gerne unterwegs bist.</p>
          </Col>
        </Row>
      </Box>

      <Box boxTitle='Bambus'>
        <StaticImage src="https://mianzi.gitlab.io/mianzi-website/img/orig/blatt.png" alt="Warum Bambus" className="float-end symbolic-image" />
        <div className="text-center">
          <h1>Bambus als Werkstoff</h1>
        </div>
        <Row>
          <Col md={7}>
            <StaticImage src="https://mianzi.gitlab.io/mianzi-website/img/bambus.jpg" alt="Bambus als Werkstoff" className="p-3" style={{ borderRadius: "5px" }} />
          </Col>
          <Col md={5} className="text-secondary">
            <p>Bambus ist für uns aus verschiedenen Gründen ein faszinierender Werkstoff. Erstens ist Bambus eine sehr nachhaltige und erneuerbare Ressource. Es ist eine der schnellst wachsenden Pflanzen der Welt und kann geerntet werden, ohne dass das Wurzelsystem beschädigt wird, wodurch es schnell wieder nachwachsen kann. Im Gegensatz dazu erfordern viele andere Materialien, die bei der Fahrradherstellung verwendet werden, wie Stahl oder Aluminium, große Mengen an Energie und Ressourcen zur Extraktion und Verarbeitung und können negative Auswirkungen auf die Umwelt haben.</p>
            <p>Zweitens ist Bambus natürlich resistent gegen Schädlinge und Krankheiten, was bedeutet, dass es ohne den Einsatz von schädlichen Chemikalien oder Pestiziden angebaut werden kann. Dies macht Bambus im Vergleich zu anderen Materialien, die möglicherweise den Einsatz solcher Chemikalien zur Vermeidung von Schäden oder Verfall erfordern, umweltfreundlicher.
            </p>
            <p>Drittens ist Bambus ein leichtes und starkes Material, das für die Verwendung in Fahrradrahmen geeignet ist. Dies bedeutet, dass Bambus-Fahrräder so gestaltet werden können, dass sie langlebig und leicht sind, was die Gesamtumweltauswirkungen des Fahrrads verringert.
            </p>
            <p>Insgesamt bietet die Kombination dieser Faktoren Bambus als umweltfreundlichere Option für die Fahrradherstellung im Vergleich zu vielen anderen Materialien.</p>
          </Col>
        </Row>
      </Box>

      <Box boxTitle='fahrrad'>
        <StaticImage src="https://mianzi.gitlab.io/mianzi-website/img/orig/fahrrad.png" alt="Fahrradsymbol" className="float-end symbolic-image" />
        <div className="text-center">
          <h1>Das LaMa</h1>
        </div>
        <Row>
          <Col md={7}>
            <StaticImage src="https://mianzi.gitlab.io/mianzi-website/img/hilde3.jpg" alt="Unser Bambuslastenfahrrad" className="p-3" style={{ borderRadius: "5px" }} />
          </Col>
          <Col md={5} className="text-secondary">
            <p>
              Jedes unserer Lastenräder ist ein echtes Unikat und wird von Hand gefertigt. Wir haben jedes Detail sorgfältig durchdacht und viel Engineering-Power in die Entwicklung gesteckt, um ein möglichst perfektes Fahrrad zu schaffen. Der Bambusrahmen ist stabil und absorbiert Stöße, wodurch du 80 kg oder mehr auf der Ladefläche transportieren kannst</p>
            <p>Unser Bambus-Fahrrad ist nicht nur gut für die Umwelt, sondern auch elegant und sportlich. Du kannst es für den täglichen Einsatz in urbanen Umgebungen nutzen, aber auch für Fahrradurlaube. Wir bieten es auch mit elektrischer Unterstützung an, um das Fahren noch angenehmer zu machen.
            </p>
          </Col>
        </Row>
      </Box>

      <Box boxTitle='team' style='sand'>
        <div className="text-center sand-box">
          <h1>Wir sind LaMa</h1>
          <p>Unser gemeinsames Ziel: eine Welt mit weniger Autos und mehr Fahrrädern</p>
        </div>

        <Row className="text-white text-center">
          <div className="col-lg-3 col-sm-6 p-3">
            <StaticImage
              src="https://mianzi.gitlab.io/mianzi-website/img/orig/Anna.jpg"
              alt="Anna"
              className="img-fluid rounded-circle"
              imgClassName="rounded-circle"
            />
            <p className="p-2">Anna</p>
          </div>

          <div className="col-lg-3 col-sm-6 p-4">
            <StaticImage
              src="https://mianzi.gitlab.io/mianzi-website/img/orig/Sebastian.jpg"
              alt="Sebastian"
              className="img-fluid rounded-circle"
              imgClassName="rounded-circle"
            />
            <p className="p-2">Sebastian</p>
          </div>

          <div className="col-lg-3 col-sm-6 p-3">
            <StaticImage
              src="https://mianzi.gitlab.io/mianzi-website/img/orig/Jonas.jpg"
              alt="Jonas"
              className="img-fluid rounded-circle"
              imgClassName="rounded-circle"
            />
            <p className="p-2">Jonas</p>
          </div>

          <div className="col-lg-3 col-sm-6 p-3">
            <StaticImage
              src="https://mianzi.gitlab.io/mianzi-website/img/orig/Andreas.jpg"
              alt="Andreas"
              className="img-fluid rounded-circle"
              imgClassName="rounded-circle"
            />
            <p className="p-2">Andreas</p>
          </div>
        </Row>
      </Box>

      <ApiResult />

      <Box boxTitle='Interesse'>
        <div className="text-center">
          <h1>Interesse?</h1>
          <ContactForm />
        </div>
      </Box>

    </main>
  )
}

export default IndexPage

export const Head: HeadFC = () => {
  return (
    <>
      <title>LaMa - Lastenfahrräder aus Bambus</title>
      <meta name="description" content="LaMa - Lastenfahrräder aus Bambus" />
      <meta name="keywords" content="MIANZI, LaMa, Fahrrad, Bambusfahrrad, Lastenfahrrad, Bambus, Longjohn" />
    </>
  )
}

