import * as React from 'react'

import Container from 'react-bootstrap/Container';

const boxStyle = {
    //width: "60%",
    
    //margin: "10px",
    //padding: "10px",
    
}

const whiteStyle = {
    ...boxStyle, 
    borderRadius: "5px",
    backgroundColor: "white",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
}

const sandStyle = {
    ...boxStyle,
    backgroundColor: "#8ac7aa",
}

interface BoxProps {
    boxTitle: string,
    style?: 'white' | 'sand', 
    children: any
}

const Box = (props: BoxProps) => {
    const appliedStyle = props.style === 'sand' ? sandStyle : whiteStyle // white is default

    return (
        <Container className="RobotoSlabFont">
        <div id={props.boxTitle.replace(" ", "-").toLowerCase()} style={appliedStyle} className="my-3 p-5">
            {props.children}
        </div>
        </Container>
    )
}

export default Box